<template>
	<v-card class="mx-auto" elevation="0">
		<v-card-title class="text-left card-header fixed-card-title2">{{ $t("add-note") }}
        <v-spacer></v-spacer>
        <v-icon class="white--text float-end mr-1" @click="closeDrawer">mdi-close</v-icon>
		</v-card-title>
		<v-card-text  class="px-0" :key="rerender">
      <v-row>
        <v-col cols="11">
          <div class="d-flex flex-column text-left">
            <v-select
                class="ml-2 my-2"
                v-model="selectedTemplate"
                :items="medicalNotesCategoriesForHospital"
                item-text="medicalNoteCategoryName"
                item-value="id"
                label="Select Template"
                outlined
                dense
                hide-details
                auto
                return-object
                :menu-props="{ offsetY: true }"
                @change="getTemplateDescription"
            >
              <template v-slot:item="data">
                <div class="d-flex justify-start align-center">
                  <span class="font-weight-medium font-size15">{{ data.item.medicalNoteCategoryName }}</span>
                </div>
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col cols="1">
          <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  class=" pa-0 my-4 ml-n5"
                  @click="menu = !menu"
              >
                mdi-calendar
              </v-icon>
            </template>
            <v-date-picker @change="dateSelected" v-model="calendarDate" @input="menu = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-textarea
          class="mt-2 px-2 font-size12-5"
          :height="pageHeight(50)"
          solo
          no-resize
          v-model="medicalNote"
      >
<!--        <template v-if="showCheckboxForEmail" v-slot:append>-->
<!--          <v-tooltip top color="primary">-->
<!--            <template v-slot:activator="{on}">-->
<!--&lt;!&ndash;              TODO: vo ifot go zemame properti od template&ndash;&gt;-->
<!--              <v-simple-checkbox-->

<!--                  color="primary"-->
<!--                  v-model="sendMail"-->
<!--                  v-on="on"-->
<!--                  style="position: absolute; bottom: 15px; right: 30px"-->
<!--              ></v-simple-checkbox>-->
<!--            </template>-->
<!--            <span>Send this note via email</span>-->
<!--          </v-tooltip>-->
<!--        </template>-->
      </v-textarea>
        <v-row dense>
          <v-col class="d-flex pa-1" cols="6" sm="6">
            <v-card class="d-flex flex-column elevation-0">
              <div class="mt-auto">
                <span class="font-weight-bold color-black">{{$t('allow-others-to-comment')}}</span>
                <v-btn-toggle
                    v-model="commentedFromOthers"
                    row
                    active-class="chips white--text"
                    mandatory
                    class="white"
                >
                  <v-btn
                      class="tabs-class"
                      :value="true"
                      :input-value="commentedFromOthers"
                  >{{$t("yes")}}</v-btn>
                  <v-btn
                      class="tabs-class"
                      :value="false"
                      :input-value="commentedFromOthers"
                  >{{$t("no")}}</v-btn>
                </v-btn-toggle>
              </div>
            </v-card>
          </v-col>
          <v-col
              class="d-flex"
              cols="6"
              sm="6"
          >
            <v-card class="d-flex flex-column elevation-0">
              <div class="mt-auto">
                <span class="font-weight-bold color-black">{{$t('allow-others-to-edit')}}</span>
                <v-btn-toggle
                    v-model="editedFromOthers"
                    row
                    active-class="chips white--text"
                    mandatory
                    class="white"
                >
                  <v-btn
                      class="tabs-class"
                      :value="true"
                      :input-value="editedFromOthers"
                  >{{$t("yes")}}</v-btn>
                  <v-btn
                      class="tabs-class"
                      :value="false"
                      :input-value="editedFromOthers"
                  >{{$t("no")}}</v-btn>
                </v-btn-toggle>

              </div>
            </v-card>
          </v-col>
        </v-row>
    </v-card-text>
		<v-card-actions class="fixed-card-actions">
			<v-row class="mb-0">
				<v-col cols="6">
					<v-btn
            elevation="0"
						@click="closeDrawer"
            class="cancel-button-style capitalize-text"
					>
						{{ $t("cancel") }}
					</v-btn>
				</v-col>
				<v-col cols="6">
					<v-btn
            :disabled="medicalNote === ''"
						@click="createMedicalNoteForPatient"
            class="save-button-style"
					>
						{{ $t("save") }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card-actions>
	</v-card>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
export default {
  data () {
		return {
      menu: false,
      calendarDate: new Date().toISOString().slice(0, 10), // Default date
      selectedTemplate: null,
      selectedMedicalNoteCategory: null,
			medicalNote: '',
			rerender: 1,
      selectedTemplateId: 1,
      commentedFromOthers: true,
      editedFromOthers: false,
      medicalNoteEmpty: true,
			isLoading: false,
      billingSecondsForThisMedicalNoteCategory: null,
      medicalNoteCategoryName: null,
      measurementsByDeviceType: {},
      formatedReport: null,
      medicalNoteNameAdditionalData: '',
      medicalNoteStartDate: null,
      medicalNoteEndDate: null,
      assignedHospitalId: null,
      sendMail: false,
      showCheckboxForEmail: false,
		};
	},
	computed: {
		...mapState({
      updateDrawer: (state) => state.updateDrawer,
      filter: (state) => state.filter,
      patientId: (state) => state.patientId,
      selectedDate: (state) => state.selectedDate,
      userData: (state) => state.authentication.userData,
			detailedUser: (state) => state.users.detailedUser,
      hubs: (state) => state.nosessionhubs.hubs,
    }),
		...mapGetters({
			roleName: 'authentication/getRole',
      pageHeight: 'pageHeight',
      hospitalId: 'authentication/getHospitalId',
      userSettings: 'authentication/getUserSettings',
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    medicalNotesCategoriesForHospital () {
        const allowedTypes = ['MN-3RDS', 'MN-GENERAL', 'MN-NO-READING', 'MN-ABNORMAL-READING', 'MN-NORMAL-READING', 'MN-DRUGS', 'MN-HOSPITAL']; // Specify the types you want to include
        return this.$store.state.medicalnotecategory.medicalNotesCategoriesForHospital.filter(noteCategory => {
          return allowedTypes.includes(noteCategory.medicalNoteCategoryType);
        });
    },
	},
  watch: {
    updateDrawer: {
      handler (newVal) {
        this.calendarDate = this.selectedDate;
        if (newVal) {
          this.assignedHospitalId = localStorage.getItem('selectedHospitalAssignedId');
          this.$store.dispatch('medicalnotecategory/medicalnotecategorytemplateforhospital', this.assignedHospitalId);
        }
      },
      deep: true,
    },
  },
  mounted () {
    this.calendarDate = this.selectedDate;
    this.assignedHospitalId = localStorage.getItem('selectedHospitalAssignedId');
    this.$store.dispatch('medicalnotecategory/medicalnotecategorytemplateforhospital', this.assignedHospitalId);
  },
  methods: {
    dateSelected (val) {
      if (this.selectedTemplate === null || this.selectedTemplate === undefined) {
        return;
      }
      this.composeReport(this.selectedTemplate);
    },
    formatDateToUS (date) {
      // Split the ISO date into its components
      const [year, month, day] = date.split('-');
      // Rearrange the components to match the US format (MM/DD/YYYY)
      return `${month}/${day}/${year}`;
    },
    composeReport (val) {
      switch (val.medicalNoteCategoryType) {
        // Daily medical review Normal reading  DAILY
        case 'MN-NORMAL-READING':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          // this.createFormatReportWithMeasurements();
          this.createNormalDailyReview();
          break;
          // Daily medical review Abnormal reading  DAILY
        case 'MN-ABNORMAL-READING':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          // this.createFormatReportWithMeasurements();
          this.createAbnormalDailyReview();
          break;
        // Daily medical review No reading DAILY
        case 'MN-NO-READING':
          // this.medicalNoteStartDate = this.firstDayOfSelectedMonth();
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          // this.createFormatReportWithMeasurements();
          // this.createDailyReviewWithMeasurements();
          this.createNoReadingReview();
          break;
        // Weekly medical review  WEEKLY (Normal)
        case 'MN-WEEKLY-NORMAL':
          this.medicalNoteStartDate = this.firstDayOfCurrentWeek();
          this.medicalNoteEndDate = this.lastDayOfCurrentWeek();
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString()) + '-' + this.formatDateToUS(this.medicalNoteEndDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          // this.createFormatReportWithMeasurements();
          this.createWeeklyNormalReview();
          break;
          // Weekly medical review  WEEKLY (Abnormal)
        case 'MN-WEEKLY-ABNORMAL':
          this.medicalNoteStartDate = this.firstDayOfCurrentWeek();
          this.medicalNoteEndDate = this.lastDayOfCurrentWeek();
          // this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          // this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString()) + '-' + this.formatDateToUS(this.medicalNoteEndDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          // this.createFormatReportWithoutArguments();
          this.createWeeklyAbnormalReview();

          break;
        // Monthly medical review  MONTHLY
        case 'MN-MONTHLY':
          this.medicalNoteStartDate = this.firstDayOfSelectedMonth();
          this.medicalNoteEndDate = this.lastDayOfSelectedMonth(new Date(this.calendarDate)); // new Date(this.calendarDate).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatMonthAndYear(new Date(this.calendarDate.toString()));
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          // this.createFormatReportWithMeasurements();
          this.createMonthlyReview();
          break;

          // Medical Note New to RPM services
        case 'MN-NEW-RPM':
          this.medicalNoteStartDate = this.firstDayOfSelectedMonth();
          this.medicalNoteEndDate = this.lastDayOfSelectedMonth(new Date(this.calendarDate)); // new Date(this.calendarDate).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          this.createNewToRpmReview();
          // this.createFormatReportWithoutArguments();
          break;
        // General medical note  DAILY
        case 'MN-GENERAL':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.createFormatReportWithoutArguments();
          break;
        // Measurement read from 3rd party system DAILY
        case 'MN-3RDS':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.generateReport(this.medicalNoteStartDate, this.medicalNoteEndDate, val.medicalNoteCategoryType);
          this.createFormatReportWithoutArguments();
          break;
        case 'MN-DRUGS':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.createFormatReportWithoutArguments();
          break;
          // Medical Note In Hospital
        case 'MN-HOSPITAL':
          this.medicalNoteStartDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteEndDate = new Date(this.calendarDate.toString()).toISOString().slice(0, 10);
          this.medicalNoteNameAdditionalData = this.formatDateToUS(this.medicalNoteStartDate.toString());
          this.createFormatReportWithoutArguments();
          break;
      }
    },
    // Method using toLocaleString
    formatMonthAndYear (date) {
      // Get the year
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      // Return the formatted string
      return `${month}, ${year}`;
    },
    // firstDayOfCurrentWeek () {
    //   const tempDate = new Date(this.calendarDate.toString());
    //   const dayOfWeek = tempDate.getDay();
    //   const diff = tempDate.getDate() - dayOfWeek;
    //   tempDate.setDate(diff);
    //   return tempDate.toISOString().slice(0, 10);
    // },
    firstDayOfCurrentWeek () {
      const tempDate = new Date(this.calendarDate.toString());
      const dayOfWeek = tempDate.getDay();
      const diff = tempDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
      tempDate.setDate(diff);
      return tempDate.toISOString().slice(0, 10);
    },
    lastDayOfCurrentWeek () {
      const tempDate = new Date(this.calendarDate.toString());
      const dayOfWeek = tempDate.getDay();
      const diff = tempDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1) + 6;
      tempDate.setDate(diff);
      return tempDate.toISOString().slice(0, 10);
    },
    firstDayOfSelectedMonth () {
      const tempDate = new Date(this.calendarDate.toString());
      tempDate.setDate(1); // Set the date to the 1st of the current month
      return tempDate.toISOString().slice(0, 10); // Return the date in YYYY-MM-DD format
    },
    lastDayOfSelectedMonth (date) {
      const today = new Date();
      const year = date.getFullYear();
      const month = date.getMonth();

      // Set the date to the last day of the selected month
      const lastDay = new Date(year, month + 1, 1);

      // Check if today's date is after the last day of the selected month
      if (today > lastDay) {
        // If it is, return the last day of the selected month
        return lastDay.toISOString().slice(0, 10);
      } else {
        // Otherwise, return today's date
        return today.toISOString().slice(0, 10);
      }
    },
// lastDayOfSelectedMonth (date) {
    //   const today = new Date();
    //   const year = date.getFullYear();
    //   const month = date.getMonth();
    //
    //   // Set the date to the last day of the selected month
    //   const lastDay = new Date(year, month + 1, 0);
    //
    //   // Check if today's date is after the last day of the selected month
    //   if (today > lastDay) {
    //     // If it is, return the last day of the selected month
    //     return lastDay.toISOString().slice(0, 10);
    //   } else {
    //     // Otherwise, return today's date
    //     return today.toISOString().slice(0, 10);
    //   }
    // },
    // TODO in future we need to generate/compose report base on Medical note category type
    generateReport (startDate, endDate, medicalNoteCategoryType) {
      this.formatedReport = '';
      // eslint-disable-next-line no-unused-vars
      let filteredData = [];
      // Iterate over each object in assignedMedicalDevicesList array
      filteredData = this.hubs.assignedMedicalDevicesList.map(device => {
        const filteredMeasurements = device.measurementsData
            .filter(measurement => {
              // Check if the measuredAtDate is within the date range
              const measuredDate = new Date(measurement.measuredAtDate.slice(0, 10));
              const start = new Date(startDate);
              const end = new Date(endDate);
              return measuredDate >= start && measuredDate <= end;
            })
            .sort((a, b) => {
              // Convert measuredAtDate strings to Date objects for comparison
              const dateA = new Date(a.measuredAtDate);
              const dateB = new Date(b.measuredAtDate);
              // Sort in descending order based on measuredAtDate
              return dateB - dateA;
            });
        // Parse the measuredDataJson field for each measurement
        filteredMeasurements.forEach(measurement => {
          measurement.measuredData = JSON.parse(measurement.measuredDataJson);

          // This line causing problem
          // delete measurement.measuredDataJson;

          measurement.measuredAtLocalTime = new Date(measurement.measuredAtUtc).toLocaleString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                });

          // Find the deviceDefaultExtremeValuesJson and deviceDefaultValuesJson for the corresponding deviceType
          const matchingDevice = this.hubs.assignedMedicalDevicesList.find(d => d.deviceType === measurement.deviceType);
          if (matchingDevice) {
            measurement.deviceDefaultExtremeValues = JSON.parse(matchingDevice.deviceDefaultExtremeValuesJson);
            measurement.deviceDefaultValues = JSON.parse(matchingDevice.deviceDefaultValuesJson);
            measurement.deviceName = matchingDevice.deviceName;
            measurement.deviceType = matchingDevice.deviceType;
            measurement.isAbnormal = false;
          }
        });

        // Return the device object with filtered measurementsData
        return {
          deviceName: device.deviceName,
          measurementsData: filteredMeasurements,
        };
      });

      // Merge measurementsData from all devices into one array
      const mergedMeasurements = filteredData.reduce((merged, device) => {
        merged.push(...device.measurementsData);
        return merged;
      }, []);

      // Aggregate measurements by deviceType and count the number of measurements
      this.measurementsByDeviceType = mergedMeasurements.reduce((agg, measurement) => {
        const deviceType = measurement.deviceType;
        if (!agg[deviceType]) {
          agg[deviceType] = {
            deviceName: measurement.deviceName,
            deviceType: measurement.deviceType,
            count: 0,
            extremes: {
              minValue: {
                value: 0,
                readableFormat: '',
                measuredAtUTC: null,
                measuredAtLocalTime: null,
              },
              maxValue: {
                value: 0,
                readableFormat: '',
                measuredAtUTC: null,
                measuredAtLocalTime: null,
              },
            },
            measurements: [],
          };
        }
        agg[deviceType].count++;
        agg[deviceType].measurements.push(measurement);
        return agg;
      }, []);

      this.findMinAndMax();
    },
    // createFormatDalyReportWithNormalMeasurements () {
    //   // Daily review of BP completed, last BP obtained this morning WNL: 126/84, some lower BPs noted over the weekend. Will continue to monitor and update PCP as needed.
    //   this.formatedReport = '';
    //   // Compile data for each measurement in text form
    //   const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
    //   this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;
    //   if (this.measurementsByDeviceType.length > 0) {
    //     for (const deviceType in this.measurementsByDeviceType) {
    //       const { count, extremes, measurements } = this.measurementsByDeviceType[deviceType];
    //       this.formatedReport += `${this.formatMeasurementName(deviceType)}: `;
    //       this.formatedReport += `counts:(${count}) `;
    //       this.formatedReport += `max: ${extremes.maxValue.readableFormat} `;
    //       this.formatedReport += `min: ${extremes.minValue.readableFormat}\n`;
    //       measurements.forEach((measurement, index) => {
    //         this.formatedReport += `${index + 1}. `;
    //         this.formatedReport += `${measurement.measuredAtLocalTime} `;
    //         this.formatedReport += `measured: ${this.formatMeasurement(measurement.measuredData, measurement.deviceDefaultValues)}\n`;
    //       });
    //       this.formatedReport += '\n';
    //     }
    //   } else {
    //     this.formatedReport += 'There are no readings for this patient\n';
    //   }
    //   this.medicalNote = this.formatedReport;
    // },
    createFormatReportWithMeasurements () {
      this.formatedReport = '';
      // Compile data for each measurement in text form
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;
      if (this.measurementsByDeviceType.length > 0) {
        for (const deviceType in this.measurementsByDeviceType) {
          const { count, extremes, measurements } = this.measurementsByDeviceType[deviceType];
          this.formatedReport += `${this.formatMeasurementName(deviceType)}: `;
          this.formatedReport += `counts:(${count}) `;
          this.formatedReport += `max: ${extremes.maxValue.readableFormat} `;
          this.formatedReport += `min: ${extremes.minValue.readableFormat}\n`;
          measurements.forEach((measurement, index) => {
            this.formatedReport += `${index + 1}. `;
            this.formatedReport += `${measurement.measuredAtLocalTime} `;
            this.formatedReport += `measured: ${this.formatMeasurement(measurement.measuredData, measurement.deviceDefaultValues)}\n`;
          });
          this.formatedReport += '\n';
        }
      } else {
        this.formatedReport += 'There are no readings for this patient\n';
      }
      this.medicalNote = this.formatedReport;
    },
    createNormalDailyReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';

      if (this.measurementsByDeviceType.length > 0) {
        // for (const deviceType in this.measurementsByDeviceType) {
          const { count, extremes } = this.measurementsByDeviceType[3];
          tempCounts = count;
          tempBPMMax = extremes.maxValue.readableFormat;
          tempBPMMin = extremes.minValue.readableFormat;
        // }
      }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createAbnormalDailyReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';

      if (this.measurementsByDeviceType.length > 0) {
        // for (const deviceType in this.measurementsByDeviceType) {
          const { count, extremes } = this.measurementsByDeviceType[3];
          tempCounts = count;
          tempBPMMax = extremes.maxValue.readableFormat;
          tempBPMMin = extremes.minValue.readableFormat;
        // }
      }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createNoReadingReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';
      let lastMeasuredDateTime = '[SINCE-DAYS]';

      if (this.measurementsByDeviceType.length > 0) {
        // for (const deviceType in this.measurementsByDeviceType) {
          const { count, extremes, measurements } = this.measurementsByDeviceType[3];
          lastMeasuredDateTime = measurements[0].measuredAtLocalTime;
          tempCounts = count;
          tempBPMMax = extremes.maxValue.readableFormat;
          tempBPMMin = extremes.minValue.readableFormat;
        }
      // }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[SINCE-DAYS]', lastMeasuredDateTime)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createWeeklyNormalReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';
      let lastMeasuredDateTime = '[SINCE-DAYS]';

      if (this.measurementsByDeviceType.length > 0) {
        // for (const deviceType in this.measurementsByDeviceType) {
          const { count, extremes, measurements } = this.measurementsByDeviceType[3];
          lastMeasuredDateTime = measurements[0].measuredAtLocalTime;
          tempCounts = count;
          tempBPMMax = extremes.maxValue.readableFormat;
          tempBPMMin = extremes.minValue.readableFormat;
        }
      // }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[SINCE-DAYS]', lastMeasuredDateTime)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createWeeklyAbnormalReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';
      let lastMeasuredDateTime = '[SINCE-DAYS]';

      if (this.measurementsByDeviceType.length > 0) {
        // for (const deviceType in this.measurementsByDeviceType) {
          const { count, extremes, measurements } = this.measurementsByDeviceType[3];
          lastMeasuredDateTime = measurements[0].measuredAtLocalTime;
          tempCounts = count;
          tempBPMMax = extremes.maxValue.readableFormat;
          tempBPMMin = extremes.minValue.readableFormat;
        }
      // }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[SINCE-DAYS]', lastMeasuredDateTime)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createMonthlyReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';
      let lastMeasuredDateTime = '[SINCE-DAYS]';

      if (this.measurementsByDeviceType.length > 0) {
        // for (const deviceType in this.measurementsByDeviceType) {
          const { count, extremes, measurements } = this.measurementsByDeviceType[3];
          lastMeasuredDateTime = measurements[0].measuredAtLocalTime;
          tempCounts = count;
          tempBPMMax = extremes.maxValue.readableFormat;
          tempBPMMin = extremes.minValue.readableFormat;
        }
      // }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[SINCE-DAYS]', lastMeasuredDateTime)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createNewToRpmReview () {
      this.formatedReport = '';

      // Find the selected template
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;

      // Initialize default placeholder values
      let tempCounts = '[MEASUREMENT-COUNT]';
      let tempBPMMax = '[BPM-MAX]';
      let tempBPMMin = '[BPM-MIN]';
      let firstMeasuredDateTime = '[FIRST-DATE]';

      if (this.measurementsByDeviceType.length > 0) {
        // for (const deviceType in this.measurementsByDeviceType) {
          const { count, extremes, measurements } = this.measurementsByDeviceType[3];
        firstMeasuredDateTime = measurements[measurements.length - 1].measuredAtLocalTime;
          tempCounts = count;
          tempBPMMax = extremes.maxValue.readableFormat;
          tempBPMMin = extremes.minValue.readableFormat;
        }
      // }

      // Replace placeholders in the template
      this.formatedReport = this.formatedReport
          .replace('[MEASUREMENT-COUNT]', tempCounts)
          .replace('[FIRST-DATE]', firstMeasuredDateTime)
          .replace('[BPM-MAX]', tempBPMMax)
          .replace('[BPM-MIN]', tempBPMMin);

      // Assign the final report to the medicalNote
      this.medicalNote = this.formatedReport;
    },
    createFormatReportWithoutArguments () {
      this.formatedReport = '';
      // Compile data for each measurement in text form
      const selectedTemplate = this.medicalNotesCategoriesForHospital.find(item => item.id === this.selectedTemplate.id);
      this.formatedReport = `${selectedTemplate.medicalNoteCategoryDescription}\n\n`;
      this.medicalNote = this.formatedReport;
    },

    formatMeasurementName (deviceType) {
      switch (parseInt(deviceType)) {
        case 1:
          return this.$t('temperature');
        case 2:
          return this.$t('oximetry');
        case 3:
          return this.$t('bloodPressure');
        case 5:
          return this.$t('weight');
        case 6:
          return this.$t('glucometry');
        default:
          return '';
      }
    },
    formatMeasurement (data, range) {
      switch (parseInt(data.device_type)) {
        case 1:
          if (this.userSettings.Units.toLowerCase() === 'metric') {
            if (data.isAbnormal) {
              return data?.Temperature_C + ' °C (Abnormal)';
            } else {
              return data?.Temperature_C + ' °C (Normal)';
            }
          } else if (this.userSettings.Units.toLowerCase() === 'imperial') {
            if (data.isAbnormal) {
              return data?.Temperature_F + ' °F (Abnormal)';
            } else {
              return data?.Temperature_F + ' °F (Normal)';
            }
          }
          break;
        case 3:
        if (data.isAbnormal) {
              return data.Sys + '/' + data.Dias + ' mmHg (Abnormal)';
            } else {
              return data.Sys + '/' + data.Dias + ' mmHg (Normal)';
            }
        case 2:
        if (data.isAbnormal) {
              return data?.Spo2 + '% (Abnormal)';
            } else {
              return data?.Spo2 + '% (Normal)';
            }
        case 5:
          if (this.userSettings.Units.toLowerCase() === 'metric') {
            return data.weight_kg + ' kg';
          } else return data.weight_lbs + ' lb';
        case 6:
          //  isMeasuredBeforeMeal
          if (data.isAbnormal) {
            if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
              return parseFloat(data.glucose_mmolL).toFixed(1) + ' mmol/L (Abnormal)';
            } else return parseFloat(data.glucose_mgL).toFixed(1) + ' mg/dL (Abnormal)';
          } else {
            if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
              return parseFloat(data.glucose_mmolL).toFixed(1) + ' mmol/L (Normal)';
            } else return parseFloat(data.glucose_mgL).toFixed(1) + ' mg/dL (Normal)';
          }
        case 7:
          if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
            return parseFloat(data.cholesterol_mmolL).toFixed(1) + ' mmol/L';
          } else return parseFloat(data.cholesterol_mgL).toFixed(1) + ' mg/dL';
      }
    },

    findMinAndMax () {
      this.measurementsByDeviceType.forEach(deviceMeasurements => {
        const tempExtremes = {
          minValue: {
            value: Infinity,
            readableFormat: '',
            measuredAtUTC: null,
            measuredAtLocalTime: null,
          },
          maxValue: {
            value: -Infinity,
            readableFormat: '',
            measuredAtUTC: null,
            measuredAtLocalTime: null,
          },
        };

        deviceMeasurements.measurements.forEach(measurement => {
          const { deviceType, measuredData, measuredAtUtc, measuredAtLocalTime } = measurement;
          var range = measurement.deviceDefaultValues;

          switch (parseInt(deviceType)) {
            case 1: // Temperature device
              // eslint-disable-next-line no-case-declarations
              const tempValue = this.userSettings.Units.toLowerCase() === 'metric' ? measuredData.Temperature_C : measuredData.Temperature_F;
              // eslint-disable-next-line no-case-declarations
              const tempUnit = this.userSettings.Units.toLowerCase() === 'metric' ? '°C' : '°F';

              if (measuredData.Temperature_C > range.MaxTemperature || measuredData.Temperature_C < range.MinTemperature) {
                measuredData.isAbnormal = true;
                measurement.isAbnormal = true;
              } else {
                measuredData.isAbnormal = false;
                measurement.isAbnormal = false;
              }

              if (measuredData.Temperature_C < tempExtremes.minValue.value) {
                tempExtremes.minValue = {
                  value: tempValue,
                  readableFormat: tempValue + tempUnit,
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }

              if (measuredData.Temperature_C > tempExtremes.maxValue.value) {
                tempExtremes.maxValue = {
                  value: tempValue,
                  readableFormat: tempValue + tempUnit,
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }
              break;

            case 2: // SPO2 device
              if (measuredData.Spo2 < range.SpO2) {
                measuredData.isAbnormal = true;
                measurement.isAbnormal = true;
              } else {
                measuredData.isAbnormal = false;
                measurement.isAbnormal = false;
              }

              if (measuredData.Spo2 < tempExtremes.minValue.value) {
                tempExtremes.minValue = {
                  value: measuredData.Spo2,
                  readableFormat: measuredData.Spo2 + '%',
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }

              if (measuredData.Spo2 > tempExtremes.maxValue.value) {
                tempExtremes.maxValue = {
                  value: measuredData.Spo2,
                  readableFormat: measuredData.Spo2 + '%',
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }
              break;

            case 3: // Blood pressure device (Sys)
              var systolicChecking = measuredData.Sys > range.SystolicMax || measuredData.Sys < range.SystolicMin;
              var diastolicChecking = measuredData.Dias > range.DiastolicMax || measuredData.Dias < range.DiastolicMin;
              var heartBeatChecking = measuredData.Pul > range.HeartBeatMax || measuredData.Pul < range.HeartBeatMin;
              if (systolicChecking || diastolicChecking || heartBeatChecking) {
                measuredData.isAbnormal = true;
                measurement.isAbnormal = true;
              } else {
                measuredData.isAbnormal = false;
                measurement.isAbnormal = false;
              }
              if (measuredData.Sys < tempExtremes.minValue.value) {
                tempExtremes.minValue = {
                  value: measuredData.Sys,
                  readableFormat: measuredData.Sys + '/' + measuredData.Dias + 'mmHg',
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }

              if (measuredData.Sys > tempExtremes.maxValue.value) {
                tempExtremes.maxValue = {
                  value: measuredData.Sys,
                  readableFormat: measuredData.Sys + '/' + measuredData.Dias + 'mmHg',
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }
              break;
              case 5: // Weight scale
              measuredData.isAbnormal = false;
              measurement.isAbnormal = false;
              // eslint-disable-next-line no-case-declarations
              const scaleValue = this.userSettings.Units.toLowerCase() === 'metric' ? measuredData.weight_kg : measuredData.weight_lbs;
              // eslint-disable-next-line no-case-declarations
              const scaleUnit = this.userSettings.Units.toLowerCase() === 'metric' ? 'Kg' : 'lbs';

              if (scaleValue < tempExtremes.minValue.value) {
                tempExtremes.minValue = {
                  value: scaleValue,
                  readableFormat: scaleValue + scaleUnit,
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }

              if (scaleValue > tempExtremes.maxValue.value) {
                tempExtremes.maxValue = {
                  value: scaleValue,
                  readableFormat: scaleValue + scaleUnit,
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }
              break;

            case 6: // Blood sugar
              // eslint-disable-next-line no-case-declarations
              const bloodSugarValue = this.userSettings.Concentration.toLowerCase() === 'mmol/l' ? measuredData.glucose_mmolL : measuredData.glucose_mgL;
              // eslint-disable-next-line no-case-declarations
              const bloodSugarUnit = this.userSettings.Concentration.toLowerCase() === 'mmol/l' ? 'mmol/L' : 'mg/dL';

              var glucoseChecking = measuredData.glucose_mmolL > range.BloodSugarLevelMax || measuredData.glucose_mmolL < range.BloodSugarLevelMin;
              if (glucoseChecking) {
                measuredData.isAbnormal = true;
                measurement.isAbnormal = true;
              } else {
                measuredData.isAbnormal = false;
                measurement.isAbnormal = false;
              }

              if (bloodSugarValue < tempExtremes.minValue.value) {
                tempExtremes.minValue = {
                  value: bloodSugarValue.toFixed(1),
                  readableFormat: bloodSugarValue.toFixed(1) + bloodSugarUnit,
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }

              if (bloodSugarValue > tempExtremes.maxValue.value) {
                tempExtremes.maxValue = {
                  value: bloodSugarValue.toFixed(1),
                  readableFormat: bloodSugarValue.toFixed(1) + bloodSugarUnit,
                  measuredAtUTC: measuredAtUtc,
                  measuredAtLocalTime: measuredAtLocalTime,
                };
              }
              break;

            default:
              // Handle any other device types if necessary
              break;
          }
        });
        // Assign the computed tempExtremes to the extremes property of the current deviceMeasurements
        deviceMeasurements.extremes = tempExtremes;
      });
    },
    getTemplateDescription (val) {
      this.selectedMedicalNoteCategory = this.medicalNotesCategoriesForHospital.find(item => item.id === val.id);

      this.composeReport(val);
      // // this.medicalNote = selectedTemplate?.medicalNoteCategoryDescription;
      // this.selectedTemplateId = selectedTemplate.id;
      // this.billingSecondsForThisMedicalNoteCategory = selectedTemplate.billingSecondsForThisMedicalNoteCategory;
      // this.medicalNoteCategoryName = selectedTemplate.medicalNoteCategoryName;
    },

    async createMedicalNoteForPatient () {
      const patientId = this.$route.params.id ? this.$route.params.id : this.patientId;

      const payloadBody = {
        medicalNoteName: this.selectedMedicalNoteCategory.medicalNoteCategoryName + ': ' + this.medicalNoteNameAdditionalData,
        // medicalNotesCategories: this.medicalNotesCategoriesForHospital,
        // allowToBeEditedFromOthers: false,
        // allowToBeCommentedFromOthers: false,
        medicalNoteCategoryId: this.selectedMedicalNoteCategory.id,
        medicalNoteOfficeId: localStorage.getItem('selectedHospitalAssignedId'),
        createdByMedicalStaffId: this.userData.id,
        medicalNoteForPatientId: patientId,
        medicalNoteStartDate: this.medicalNoteStartDate,
        medicalNoteEndDate: this.medicalNoteEndDate,
        allowToBeEditedFromOthers: this.editedFromOthers,
        allowToBeCommentedFromOthers: this.commentedFromOthers,
        medicalNoteDetail: {
          // medicalNoteId: 0,
          // billingSecondsForThisMedicalNoteDetail: this.selectedMedicalNoteCategory.billingSecondsForThisMedicalNoteCategory,
          medicalStaffId: this.userData.id,
          medicalNoteContent: this.medicalNote,
          medicalNoteAdditionalData: '',
        },
      };
      await this.$store.dispatch('appointments/addMedicalNote', payloadBody);
      // TODO: if sendMail === true call the api for the mail...
      if (this.filter === 'ALL') {
        this.getAllNotes();
      } else {
        window.location.reload();
      }
      this.closeDrawer();
    },
    getAllNotes () {
      const body = {
        patientId: this.$route.params.id,
        hospitalId: localStorage.getItem('selectedHospitalAssignedId'),
      };
      this.$store.dispatch('appointments/getmedicalnoteforpatientbyid', body);
    },
    closeDrawer () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
      this.selectedTemplate = null;
      this.medicalNote = '';
    },
  },
};
</script>
<style scoped>
.v-select__selection {
  width: 100%;
  justify-content: left;
}
</style>

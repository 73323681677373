<template>
    <v-card
        class="mx-auto overflow-auto border-radius0"
        elevation="0"
        height="90%"
        v-if="item"
    >
        <v-card-title class="card-header text-left fixed-card-title2">
            <v-row>
                <v-col cols="10">
                    <v-toolbar-title >{{$t('edit')}} {{$t('medicalInstitution')}}</v-toolbar-title>
                </v-col>
              <v-spacer></v-spacer>
              <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <!-- Data -->
                <v-container>
                    <v-row class="mt-1">
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="item.institutionName"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('organization-name')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-textarea
                                outlined
                                hide-details
                                dense
                                no-resize
                                v-model="item.description"
                                :label="$t('description')"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <div class="pt-4">
                                <h5 class="heading text-left">{{this.$t('address')}}</h5>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="item.address"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('address')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="item.city"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('city')"
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="item.state"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('state')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="item.zip"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('zip')"
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="item.country"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('country')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <div class="pt-4">
                                <h5 class="heading text-left">{{this.$t('contact')}}</h5>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="item.contactPerson"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('contact-person')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="item.contactPhone"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('contact-phone')"
                                @input="formatPhoneNumber"
                                required>
                            </v-text-field>
                            <v-text-field
                                class="ml-2"
                                outlined
                                hide-details="auto"
                                dense
                                v-model="item.contactEmail"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('contact-email')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                          class="d-flex pa-1"
                          cols="12"
                          sm="12"
                      >
                        <div class="pt-4">
                          <h5 class="heading text-left">{{ $t('settings') }}</h5>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex pa-1 my-2"
                      >
                        <v-select
                            outlined
                            dense
                            hide-details
                            v-model="selectedType"
                            :items="billingMonitoringTypes"
                            :item-text="$i18n.locale === 'en' ? 'longDescription' : 'longDescriptionEsp'"
                            item-value="lowValue"
                            :label="$t('billing-monitoring-type-label')"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                          class="d-flex pa-1"
                          cols="6"
                          sm="6"
                      >
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold color-black">{{$t('enable-assessments')}}</span>
                          <v-btn-toggle
                              v-model="mentalHeath"
                              row
                              active-class="chips white--text"
                              mandatory
                              class="white"
                          >
                            <v-btn
                                class="tabs-class"
                                :value="1"
                                :input-value="mentalHeath"
                            >{{$t("yes")}}</v-btn>
                            <v-btn
                                class="tabs-class"
                                :value="0"
                                :input-value="mentalHeath"

                            >{{$t("no")}}</v-btn>
                          </v-btn-toggle>
                        </div>
                      </v-col>
                      <v-col
                          class="d-flex pa-1"
                          cols="6"
                          sm="6"
                      >
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold color-black">{{$t('enable-rtm')}}</span>
                          <v-btn-toggle
                              v-model="G3002RTM"
                              row
                              active-class="chips white--text"
                              mandatory
                              class="white"
                          >
                            <v-btn
                                class="tabs-class"
                                :value="1"
                                :input-value="G3002RTM"
                            >{{$t("yes")}}</v-btn>
                            <v-btn
                                class="tabs-class"
                                :value="0"
                                :input-value="G3002RTM"
                            >{{$t("no")}}</v-btn>
                          </v-btn-toggle>

                        </div>
                      </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions class="fixed-card-actions">
            <v-row class="mb-0">
                <v-col cols="6">
                    <v-btn
                        @click="closeDrawer"
                        elevation="0"
                        class="cancel-button-style capitalize-text"
                        >
                    {{$t('cancel')}}
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn
                        :disabled="!valid"
                        @click="saveMedicalInstitution"
                        class="save-button-style"
                    >
                    {{ $t('save') }}
                    </v-btn>
                </v-col>
            </v-row>
      </v-card-actions>
    </v-card>
</template>

<script>
    import { closeWhite } from '@/assets/index';
    import { mapGetters, mapState } from 'vuex';
    import { AsYouType } from 'libphonenumber-js';
    import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

    export default {
        name: 'MedicalInstitutionEditDrawerDialog',
        components: {},
        data: () => ({
          closeWhite: closeWhite,
            valid: true,
            menu: false,
          institutionName: '',
          description: '',
          contactPerson: '',
          contactPhone: '',
          contactEmail: '',
        }),
        mounted () {
          this.getBillingMonitoringTypes();
          this.selectedType = this.item.billingMonitoringType;
        },
          beforeDestroy () {
              this.selectedMedicalInstitution = [];
          },

        computed: {
            ...mapGetters({
                delay: 'authentication/getDelay',
            }),
          ...mapState({
            billingMonitoringTypes: (state) => state.verifiers.billingMonitoringTypes,
            item: (state) => state.item,
          }),
          selectedType: {
            get () {
              return this.item.billingMonitoringType;
            },
            set (value) {
              this.item.billingMonitoringType = value;
            },
          },
          mentalHeath: {
            get () {
              return this.item.wellnessFlagDefault ? 1 : 0;
            },
            set (value) {
              this.item.wellnessFlagDefault = value === 1;
            },
          },
          G3002RTM: {
            get () {
              return this.item.useG3002ForRTM ? 1 : 0;
            },
            set (value) {
              this.item.useG3002ForRTM = value === 1;
            },
          },
        },
          watch: {
            // selectedMedicalInstitution (val) {
            //   console.log('Value : ', val);
            // },
            // item (val) {
            //   console.log('val', val);
            // },
          },
        methods: {
          getBillingMonitoringTypes () {
            this.$store.dispatch('verifiers/getBillingMonitoringTypes');
          },
          formatPhoneNumber (val) {
            this.item.contactPhone = new AsYouType().input(val);
          },
            getAllMedicalInstitutions () {
                this.$store.dispatch('medicalInstitutions/getMedicalInstitutions').then(() => {
                    this.closeDrawer();
                });
            },
            async saveMedicalInstitution () {
                const waitAddressValidation = await this.checkAndFixLocationDetails();
                if (!waitAddressValidation) {
                    // this.validationErrors.push('address');
                    // go on with the given addres data
                }

                this.item.contactPhone = new AsYouType().input(this.item.contactPhone);

              // eslint-disable-next-line no-unused-vars
              let wellnessFlagDefault = false;
              // eslint-disable-next-line no-unused-vars
              let useG3002ForRTM = false;

              if (this.mentalHeath === 1) {
                wellnessFlagDefault = true;
              }
              if (this.G3002RTM === 1) {
                useG3002ForRTM = true;
              }

                if (this.$refs.form.validate()) {
                    const body = {
                        id: this.item.id,
                        institutionName: this.item.institutionName,
                        description: this.item.description,
                        contactPerson: this.item.contactPerson,
                        contactPhone: this.item.contactPhone,
                        address: this.item.address,
                        zip: this.item.zip,
                        city: this.item.city,
                        state: this.item.state,
                        country: this.item.country,
                        addressLatitude: this.item.addressLatitude,
                        addressLongitude: this.item.addressLongitude,
                        contactEmail: this.item.contactEmail,
                        mediaServerData: this.item.mediaServerData,
                        WellnessFlagDefault: wellnessFlagDefault,
                        UseG3002ForRTM: useG3002ForRTM,
                        billingMonitoringType: this.selectedType,

                    };

                    await this.$store.dispatch('medicalInstitutions/editMedicalInstitution', body)
                    .then((res) => {
                        if (res) {
                            showSuccessAlert(this.$t('success-operation'));
                            this.getAllMedicalInstitutions(); // reload the list of organizations
                        } else {
                            showErrorAlert(this.$t('failed-operation'));
                        }
                    });
                } else {
                    this.$refs.form.validate();
                }
            },
            save (date) {
                this.$refs.menu.save(date);
            },
            closeDrawer () {
                this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
                this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
            },
            async checkAndFixLocationDetails () {
                try {
                    if (this.item.address !== '' && this.item.city !== '' && this.item.zip !== '' && this.item.state !== '' && this.item.country !== '') {
                        var addressForValidation;
                        addressForValidation = this.item.address + ', ' + this.item.city + ', ' + this.item.state + ' ' + this.item.zip + ', ' + this.item.country;
                        const geoLocation = await this.$store.dispatch(
                            'googleAPI/getGeoLocation',
                            addressForValidation,
                        );
                        if (!geoLocation) {
                            // this.selectedMedicalInstitution.addressLatitude = 0.01;
                            // this.selectedMedicalInstitution.addressLongitude = 0.01;
                            return false;
                        }
                        if (geoLocation.address === undefined || geoLocation.address == null) {
                            // this.selectedMedicalInstitution.addressLatitude = 0.01;
                            // this.selectedMedicalInstitution.addressLongitude = 0.01;
                            return false;
                        }

                        const addressComponents = geoLocation.address;

                        this.item.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
                        this.item.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
                        this.item.address = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.item.address : geoLocation.display_name;
                        this.item.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
                        this.item.state = addressComponents.state || 'N/A';
                        this.item.country = addressComponents.country || 'N/A';
                        this.item.zip = addressComponents.postcode || 'N/A';
                        return true;
                    } else {
                        this.item.addressLatitude = 0.01;
                        this.item.addressLongitude = 0.01;
                        return false;
                    }
                } catch (error) {
                    return false;
                }
            },
        },
    };
</script>

<template>
  <div>
    <v-card-title class="card-header fixed-card-title2">
      {{$t('editBillingsAuthorizationTemplate')}}
      <v-spacer />
      <v-icon color="white" @click="closeDrawer">mdi-window-close</v-icon>
    </v-card-title>
    <v-card-text color="primary" class="mt-3" >
      <v-form>
        <v-row dense>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-select
                readonly
                outlined
                dense
                :items="hospitals"
                item-text="hospitalName"
                item-value="id"
                v-model="selectedHospital"
                append-icon="mdi-magnify"
                :label="$t('search-hospital')"
                single-line
                hide-details
                class="my-1"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-data-table
                dense
                fixed-header
                :no-data-text="$t('no-data-available')"
                :loading-text="$t('loading')"
                :headers="staffHeadr"
                :items="billingCodes"
                hide-default-footer
                item-key="id"
                :search="searchCodes"
                :height="testHeight"
                :items-per-page="-1"
                class="elevation-1"
                :footer-props="{
                itemsPerPageText: this.$t('row'),
                itemsPerPageAllText: this.$t('all'),
    }"
            >
              <template v-slot:item="{ item }">
                <tr >
                  <td class="text-left">
                    {{ item.name }}
                  </td>
                  <td>
                    <v-checkbox class="mt-n1" dense hide-details v-model="selectedItems[item.name]" />
                  </td>
                </tr>
              </template>
            </v-data-table>

          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row dense class="pa-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              class="save-button-style mx-1"
              :loading="loading"
              @click="loader = 'loading'"
          >
            <span>{{ $t("save") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
export default {
  props: ['dialog', 'data', 'deniedPatients'],
  data () {
    return {
      templateId: null,
      billingCodes: [
        { name: 'rpm99453', value: true },
        { name: 'rpm99454', value: true },
        { name: 'rpm99457', value: true },
        { name: 'rpm99458First', value: true },
        { name: 'rpm99458Second', value: true },
        { name: 'rtm96127', value: true },
        { name: 'rtm98975', value: true },
        { name: 'rtm98977', value: true },
        { name: 'rtmG3002', value: true },
        { name: 'rtm98980', value: true },
        { name: 'rtm98981', value: true },
      ],
      selectedItems: {},
      selectedHospital: null,
      loader: null,
      loading: false,
      searchCodes: '',
      staffHeadr: [
        {
          text: this.$t('codes'),
          align: 'start',
          value: 'fullName',
        },
        {
          text: '',
          align: 'end',
          value: '',
        },
      ],

    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.authentication.userData,
      getBillingsTemplateById: (state) => state.billingsauthorization.getBillingsTemplateById,
    }),
    ...mapGetters({
      hospitalId: 'authentication/getHospitalId',
    }),
    hospitals () {
      return this.$store.state.hospitals.hospitals;
    },

    testHeight () {
      if (this.$vuetify.breakpoint.width <= 1284 && this.$vuetify.breakpoint.height <= 803) {
        return '270';
      }
      return '750';
    },
  },
  watch: {
    getBillingsTemplateById: {
      handler (newVal) {
        this.updateSelectedItems(newVal);
        this.selectedHospital = newVal.officeId;
        this.templateId = newVal.id;
      },
      deep: true,
    },
    primaryDoctorSelected (val) {
      if (!this.staffSelected.includes(val)) {
        this.staffSelected.push(val);
      }
    },
    async loader () {
      if (this.loader !== null) {
        const l = this.loader;
        this[l] = !this[l];
        await this.addTemplate()
            .then(() => {
              this[l] = false;
            })
            .catch(() => {
              this[l] = false;
            });

        this.loader = null;
      }
    },
  },
  async mounted () {
    this.selectedItems = {};
    this.billingCodes.forEach(code => {
      this.$set(this.selectedItems, code.name, false);
    });
    await this.getHospitals();
  },
  methods: {
    updateSelectedItems (data) {
      this.selectedHospital = data.officeId;
      this.billingCodes.forEach(code => {
        this.$set(this.selectedItems, code.name, data[`${code.name}Allowed`]);
      });
    },
    async getHospitals () {
      this.loading = true;
      await this.$store.dispatch('hospitals/getHospitals').then(() => {
        this.loading = false;
      });
    },
    async closeDrawer () {
      this.billingCodes.forEach(code => {
        this.$set(this.selectedItems, code.name, false);
      });
      this.selectedHospital = null;
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
    },
    async addTemplate () {
      const body = {
        id: this.templateId,
        organizationId: this.userDetails.organizationId,
        officeId: this.selectedHospital,
        rpm99453Allowed: this.selectedItems.rpm99453,
        rpm99454Allowed: this.selectedItems.rpm99454,
        rpm99457Allowed: this.selectedItems.rpm99457,
        rpm99458FirstAllowed: this.selectedItems.rpm99458First,
        rpm99458SecondAllowed: this.selectedItems.rpm99458Second,
        rtm96127Allowed: this.selectedItems.rtm96127,
        rtm98975Allowed: this.selectedItems.rtm98975,
        rtm98977Allowed: this.selectedItems.rtm98977,
        rtm98980Allowed: this.selectedItems.rtm98980,
        rtm98981Allowed: this.selectedItems.rtm98981,
        rtmG3002Allowed: this.selectedItems.rtmG3002,
      };

      await this.$store.dispatch('billingsauthorization/editbillingauthorizationtemplates', body)
          .then(async (res) => {
            if (res) {
              showSuccessAlert(this.$t('success'));
            } else {
              showErrorAlert(this.$t('failed'));
            }
            await this.getBillingAuthTemplate();
            await this.closeDrawer();
          });
    },
    async getBillingAuthTemplate () {
      await this.$store.dispatch('billingsauthorization/billingauthorizationtemplates').then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

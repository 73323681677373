<template>
  <v-card
      class="mx-auto border-radius0"
      elevation="0"
  >
    <v-card-title class="card-header text-left fixed-card-title">
      <v-row>
        <v-col cols="10">
          <v-toolbar-title >{{$t('editMedicalTeam')}}</v-toolbar-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
      </v-row>
    </v-card-title>
    <!-- Role -->
    <v-card-text
        class="pt-16 mt-9 custom-height50"
        tile
        elecation="0"
    >
      <v-text-field
          class="mb-4 mt-n6 border-radius4"
          v-if="checker"
          v-model="getMedicalTeam[0].patientFullname"
          :label="$t('patient-name')"
          outlined
          dense
          :error="showMessage"
          @click="handleMessage"
          hide-details
          readonly
      ></v-text-field>
      <div>
        <p class="font-weight-medium text-left font-size13 red-icon">
          {{ message }}
          <v-icon v-if="showMessage" color="main_red" class="mt-n1">mdi mdi-alert</v-icon>
        </p>
      </div>
      <v-text-field
          class="mb-4 border-radius4"
          v-if="checker"
          v-model="getMedicalTeam[0].medicalTeamTitle"
          :label="$t('medicalTeam') + ' ' + $t('title')"
          outlined
          hide-details
          @input="handleChangedTitleDescription"
          dense
      ></v-text-field>
      <v-textarea
          class="mb-4 border-radius4"
          v-if="checker"
          v-model="getMedicalTeam[0].medicalTeamDescription"
          :label="$t('medicalTeam') + ' ' + $t('description')"
          outlined
          hide-details
          @input="handleChangedTitleDescription"
          dense
          no-resize
      ></v-textarea>
      <h6 class="heading text-left pb-3 margin-left8">{{$t('medicalTeam')}}</h6>
      <v-autocomplete
          class="mb-0 pb-6 pa-2 mt-n1"
          :label="$t('search-staff')"
          multiple
          v-model="selectedStaff"
          prepend-inner-icon="mdi-magnify"
          :items="filteredUsers"
          dense
          item-value="id"
          @input="handleStaffChange"
          hide-details
          :item-text="staff => staff.firstName + ' ' + staff.lastName"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content class="text-left">
            <v-list-item-title class="font-size14">
              {{ item.firstName }} {{ item.lastName }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <div class="pb-6">
        <v-card class="overflow-auto my-0"  :height="testHeight">
      <v-list v-for="{membersList, patientId, primaryDoctorId} in getMedicalTeam" :key="patientId"  class="my-0 py-0 custom-style6">
        <v-list-item
            v-for="{firstName, lastName, roleId, id} in membersList"
            :key="id"
            class="py-0 my-0 custom-border"
        >
          <v-row dense>
            <v-col cols="5" class="justify-start text-left d-flex align-start">
              <v-list-item-content >
                <v-list-item-title>
                  <h6 class="font-size12-5">
                    {{ firstName }} {{lastName}}
                  </h6>
                </v-list-item-title>
              </v-list-item-content>
            </v-col>
            <v-col cols="3" class="text-left justify-end d-flex">
              <v-list-item-content class="font-weight2">
                <v-list-item-subtitle  class="font-size12-5 color-black">
                  {{ roleId === 3 && id === primaryDoctorId ? $t('primary-doctor') : roleId === 3 ? $t('doctor') : $t('nurse') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-col>
            <v-col class="justify-end d-flex align-center" cols="4">
              <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                 <v-icon @click="changePrimaryDoctor(id)" size="medium" v-if="roleId===3 && id === primaryDoctorId" :disabled="id === primaryDoctorId" v-bind="attrs" v-on="on" color="main_black" class="mr-5">mdi mdi-radiobox-marked</v-icon>
                 <v-icon @click="changePrimaryDoctor(id)" size="medium" v-else-if="roleId===3" :disabled="id === primaryDoctorId" v-bind="attrs" v-on="on" color="main_black" class="mr-5">mdi mdi-radiobox-blank</v-icon>
                </template>
                <span>{{$t('makePrimary')}}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{on , attrs}">
                  <v-icon @click="dismissMember(id)" size="medium" :disabled="id===primaryDoctorId" v-bind="attrs" v-on="on"  color="main_black">mdi mdi-delete</v-icon>
                </template>
                <span>{{$t('dismiss')}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
        </v-card>
      </div>
<!--        </div>-->
    </v-card-text>
        <v-card-actions class=" mb-0 pb-0 fixed-card-actions">
          <v-row dense class="mb-0 background-white">
            <v-col cols="6">
              <v-btn
                  @click="closeDrawer"
                  elevation="0"
                  class="cancel-button-style capitalize-text"
              >
                {{$t('cancel')}}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                  class="save-button-style"
                  :disabled="!changedTitleDescription && !changedStaff"
                  @click="saveMedicalTeam"
              >
                <span>{{ $t("save") }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
  </v-card>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
export default {
	data () {
		return {
			message: '',
			showMessage: false,
			newDescription: '',
			changedStaff: false,
			selectedStaff: null,
			primaryDoctorId: '',
			changedTitleDescription: false,
		};
	},
	computed: {
		...mapState({
			userDetails: (state) => state.authentication.userData,
		}),
		...mapGetters({
			getUser: 'authentication/getUser',
			getMedicalTeam: 'medicalteam/getMedicalTeam',
			hospitalId: 'authentication/getHospitalId',
			getDoctorAndNurse: 'hospitals/getDoctorAndNurse',
			delay: 'authentication/getDelay',
			roleName: 'authentication/getRole',
		}),
    testHeight () {
      // Adjustments for common resolutions
      if (this.$vuetify.breakpoint.width <= 1280 && this.$vuetify.breakpoint.height <= 720) {
        return '220'; // 1280x720 or lower
      }
      if (this.$vuetify.breakpoint.width <= 1366 && this.$vuetify.breakpoint.height <= 768) {
        return '230'; // 1366x768 or lower
      }
      if (this.$vuetify.breakpoint.width <= 1440 && this.$vuetify.breakpoint.height <= 900) {
        return '280'; // 1440x900 or lower
      }
      if (this.$vuetify.breakpoint.width <= 1600 && this.$vuetify.breakpoint.height <= 900) {
        return '320'; // 1600x900 or lower
      }
      if (this.$vuetify.breakpoint.width <= 1920 && this.$vuetify.breakpoint.height <= 1080) {
        return '410'; // 1920x1080 or lower
      }
      if (this.$vuetify.breakpoint.width <= 1920 && this.$vuetify.breakpoint.height <= 1200) {
        return '450'; // 1920x1200 or lower
      }
      if (this.$vuetify.breakpoint.xl) {
        return '520'; // 2560x1440 or higher
      }
      // Default value for resolutions not specified above
      return '400';
    },
		filteredUsers () {
			return this?.getDoctorAndNurse?.filter((user) => {
				return (
					(user.roleId === 3 || user.roleId === 4) && !this.filteredPatient.includes(user.id)
				);
			});
		},
		filteredPatient () {
			const temp = this.getMedicalTeam.flatMap((patient) => patient.membersList.map((member) => member.id));
			return temp;
		},
		checker () {
			return this.getMedicalTeam && this.getMedicalTeam[0];
		},
	},
	methods: {
    async getMedicalTeams () {
      this.loading = true;
      await this.$store.dispatch('medicalteam/getMedicalTeams', this.getUser.hospitalAssignedData.id);
      this.loading = false;
    },
		handleMessage () {
			this.showMessage = true;
			this.message = 'Cant Change Patient Name';
			setTimeout(() => {
				this.message = '';
				this.showMessage = false;
			}, 3000);
		},
		isSelected (itemId) {
			return this.selectedStaff.includes(itemId);
		},
		handleChangedTitleDescription () {
			this.changedTitleDescription = true;
		},
		handleStaffChange () {
			this.changedStaff = true;
		},
		async changePrimaryDoctor (id) {
			this.primaryDoctorId = id;
			const body = {
				hospitalId: this.hospitalId,
				patientId: this.getMedicalTeam[0].patientId,
				primaryDoctorId: id,
			};
			await this.$store.dispatch('medicalteam/editMedicalTeam', body).then(() => {
				this.$store.commit('alerts/add', {
					id: 'updateMedicalTeam',
					type: 'success',
					color: 'green',
					message: this.$t('updatedPrimaryDoctor'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			});
			await this.$store.dispatch('medicalteam/getMedicalTeamForPatient', this.getMedicalTeam[0].patientId);
		},
		async updateDescriptionAndTitle (description, title) {
			const body = {
				hospitalId: this.userDetails.hospitalAssignedData.id, // this.hospitalId,
				patientId: this.getMedicalTeam[0].patientId,
				primaryDoctorId: this.getMedicalTeam[0].primaryDoctorId,
				medicalTeamTitle: title,
				medicalTeamDescription: description,
				status: 1,
			};
			await this.$store.dispatch('medicalteam/editMedicalTeam', body).then(() => {
				this.$store.commit('alerts/add', {
					id: 'updateMedicalTeam',
					type: 'success',
					color: 'green',
					message: this.$t('updatedDescAndTitle'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			});
		},
    async assignMemberToMedicalTeam (medicalTeamIds, staffSelected) {
      const bodyAssign = {
        medicalTeamId: medicalTeamIds,
        memberUserIdList: staffSelected,
      };
      await this.$store.dispatch('medicalteam/assignMultipleMembersToMedicalTeam', bodyAssign);
      this.$emit('false');
    },
		// async assignMemberToMedicalTeam (memberUserId, counter) {
		// 	const bodyUpdate = {
		// 		medicalTeamId: this.getMedicalTeam[0].id,
		// 		memberUserId,
		// 	};
		// 	const res = await this.$store.dispatch('medicalteam/assignMemberToMedicalTeam', bodyUpdate);
		// 	if (counter === 1 && res.resFlag === true) {
		// 		this.$store.commit('alerts/add', {
		// 			type: 'success',
		// 			message: this.$t('assignment-to-medical-team-succeeded'),
		// 		});
		// 		this.$store.commit('alerts/clear', {
		// 			type: 'success',
		// 			message: this.$t('assignment-to-medical-team-succeeded'),
		// 		});
		// 	}
		// },
		async saveMedicalTeam () {
			// if (this.newDescription !== this.getMedicalTeam[0].medicalTeamDescription || this.getMedicalTeam[0].medicalTeamTitle !== this.getMedicalTeam[0].medicalTeamTitle) {

			if (this.changedTitleDescription) {
				await this.updateDescriptionAndTitle(this.getMedicalTeam[0].medicalTeamDescription, this.getMedicalTeam[0].medicalTeamTitle);
			}
			this.changedTitleDescription = false;
			// }
      // medicalTeamId
      //     :
      //     1159
      // memberUserIdList
      //     :
      //     [544, 570, 5, 546]
      if (this.changedStaff) {
        if (this.selectedStaff && this.selectedStaff.length > 0) {
          const member = [];
          this.selectedStaff.forEach((id) => {
            if (!this.getMedicalTeam[0].membersList.some((member) => member.id === id)) {
              member.push(id);
            }
          });
          await this.assignMemberToMedicalTeam(this.getMedicalTeam[0].id, member);
        }
      }			this.changedStaff = false;
			// await this.$store.dispatch('medicalteam/getMedicalTeamForHospitalAdmin', this.getUser.id);
			await this.closeDrawer();
		},
		async closeDrawer () {
this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
      await this.getMedicalTeams();

			// // refresh medaical teams list when going back
			// if (this.roleName === 'HOSPITAL_ADMIN') {
			// 	await this.$store.dispatch('medicalteam/getMedicalTeamForHospitalAdmin', this.getUser.id);
			// } else if (this.roleName === 'DOCTOR') {
			// 	const data = { nurseId: this.getUser.id, hospitalId: this.getUser.hospitalAssignedData.id };
			// 	await this.$store.dispatch('medicalteam/getMedicalTeamForDoctor', data);
			// } else if (this.roleName === 'NURSE') {
			// 	const data = { nurseId: this.getUser.id, hospitalId: this.getUser.hospitalAssignedData.id };
			// 	await this.$store.dispatch('medicalteam/getMedicalTeamForNurse', data);
			// } else {
			// 	await this.$store.dispatch('medicalteam/getMedicalTeamForHospitalAdmin', this.getUser.id);
			// }
		},
		async dismissMember (id) {
			const bodyDelete = {
				medicalTeamId: this.getMedicalTeam[0].id,
				memberUserId: id,
			};
			await this.$store.dispatch(
				'medicalteam/deassignMemberToMedicalTeam', bodyDelete).then(res => {
				if (res.resFlag === true) {
					this.$store.commit('alerts/add', {
						id: 'addedStaff',
						type: 'success',
						color: 'green',
						message: res.msg,
					});
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				} else {
					this.$store.commit('alerts/add', {
						id: 'errorAddingStaff',
						type: 'error',
						color: 'red',
						message: res.msg,
					});
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				}
			});
			await this.$store.dispatch('medicalteam/getMedicalTeamForPatient', this.getMedicalTeam[0].patientId);
			this.selectedStaff = null;
		},
	},
};
</script>

<template>
  <div>
    <v-card-title class="card-header fixed-card-title2">
      {{$t('editBillingsTemplateforUser')}}
      <v-spacer />
      <v-icon color="white" @click="closeDrawer">mdi-window-close</v-icon>
    </v-card-title>
    <v-card-text color="primary" class="mt-3" >
      <v-form>
        <v-row dense>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-text-field
                outlined
                dense
                :value="userFullname"
                :label="$t('staff')"
                readonly
                hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-text-field
                outlined
                dense
                :value="officeName"
                :label="$t('hospital')"
                readonly
                hide-details
                class="my-1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
<!--            <v-select-->
<!--                outlined-->
<!--                dense-->
<!--                :items="getBillingsTemplatesByOffice"-->
<!--                item-text="id"-->
<!--                item-value="id"-->
<!--                v-model="selectedTemplate"-->
<!--                :label="$t('Find Template')"-->
<!--                single-line-->
<!--                hide-details-->
<!--                @input="getbillingauthorizationtemplatesbyid"-->
<!--                class="my-1"-->
<!--            ></v-select>-->
<!--            <v-select-->
<!--                :loading="loading"-->
<!--                outlined-->
<!--                dense-->
<!--                @change="getSelectedStaff"-->
<!--                :items="filteredUsers"-->
<!--                :item-text="item => `${item.firstName} ${item.lastName}`"-->
<!--                item-value="id"-->
<!--                v-model="selectUser"-->
<!--                :label="$t('Select user')"-->
<!--                single-line-->
<!--                hide-details-->
<!--                class="mt-4"-->
<!--            ></v-select>-->
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-data-table
                dense
                fixed-header
                :no-data-text="$t('no-data-available')"
                :loading-text="$t('loading')"
                :headers="billingCodesHeaders"
                :items="billingCodes"
                hide-default-footer
                item-key="id"
                :search="searchCodes"
                :height="pageHeight(80)"
                :items-per-page="-1"
                class="elevation-2 mt-n5"
                :footer-props="{
                itemsPerPageText: this.$t('row'),
                itemsPerPageAllText: this.$t('all'),
    }"
            >
              <template v-slot:item="{ item }">
                <tr >
                  <td class="text-left">
                    {{ item.name }}
                  </td>
                  <td class="px-0">
                    <v-checkbox class="mt-n1 float-end" dense hide-details v-model="selectedItems[item.name]" />
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row dense class="pa-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              class="save-button-style mx-1"
              :loading="loading"
              @click="editTemplate"
          >
            <span>{{ $t("save") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
export default {
  props: ['dialog', 'data', 'deniedPatients'],
  data () {
    return {
      filteredTemplates: [],
      getBillingsTemplatesByOffice: [],
      filteredUsers: [],
      selectUser: null,
      userFullname: '',
      officeName: '',
      selectedTemplate: null,
      templateSelected: false,
      billingCodesNew: ['Template1', 'Template2', 'Template3'],
      billingCodes: [
        { name: 'rpm99453', value: true },
        { name: 'rpm99454', value: true },
        { name: 'rpm99457', value: true },
        { name: 'rpm99458First', value: true },
        { name: 'rpm99458Second', value: true },
        { name: 'rtm96127', value: true },
        { name: 'rtm98975', value: true },
        { name: 'rtm98977', value: true },
        { name: 'rtmG3002', value: true },
        { name: 'rtm98980', value: true },
        { name: 'rtm98981', value: true },
      ],
      selectedItems: {},
      selectedRoleId: null,
      selectedHospital: null,
      selectedOrganization: this.OrganizationId,
      loader: null,
      loading: false,
      searchCodes: '',
      // staffHeadr: [
      //   {
      //     text: this.$t('codes'),
      //     align: 'start',
      //     value: 'fullName',
      //   },
      //   {
      //     text: '',
      //     align: 'end',
      //     value: '',
      //   },
      // ],

    };
  },
  computed: {
    ...mapState({
      billingTemplatesAll: (state) => state.usersbillingsauthorization.billingTemplatesAll,
      userDetails: (state) => state.authentication.userData,
      billingsTemplates: (state) => state.usersbillingsauthorization.billingsTemplates,
      allMedicalInstitutions: (state) => state.medicalInstitutions.medicalInstitutions,
      getRelatedStaff: (state) => state.hospitals.users,
      getBillingsTemplateById: (state) => state.usersbillingsauthorization.getBillingsTemplateById,
    }),
    ...mapGetters({
      hospitalId: 'authentication/getHospitalId',
      OrganizationId: 'authentication/getOrganizationId',
      roleName: 'authentication/getRole',
      pageHeight: 'pageHeight',
    }),
    billingCodesHeaders () {
      return [
        { text: this.$t('codes'), class: 'table-header', align: 'start pl-4', width: 10 },
        { text: this.$t(''), class: 'table-header', align: 'end' },
      ];
    },
    disable: {
      get () {
        return !this.userFullname || !this.officeName || !this.selectedTemplate;
      },
      set () {
      },
    },
    getUserBillingsTemplateById: {
      get () {
        return this.$store.state.usersbillingsauthorization.getUserBillingsTemplateById;
      },
      set () {
        // Leave setter empty
      },
    },
    hospitals () {
      return this.$store.state.hospitals.hospitals;
    },

    // testHeight () {
    //   if (this.$vuetify.breakpoint.width <= 1284 && this.$vuetify.breakpoint.height <= 803) {
    //     return '270';
    //   }
    //   return '750';
    // },
  },
  watch: {
    getUserBillingsTemplateById: {
      handler (newValue) {
        if (newValue) {
          this.billingCodes.forEach(code => {
            this.$set(this.selectedItems, code.name, false);
          });
          this.updateSelectedItems(newValue);

          this.selectedTemplate = this.getBillingsTemplatesByOffice[0]?.id;
          this.getBillingsTemplatesByOffice = this.billingsTemplates.filter(item => item?.officeId === this.getUserBillingsTemplateById?.officeId);
          this.userFullname = newValue.userFullname;
          this.officeName = newValue.officeName;
        }
      },
      immediate: true,
    },
    // getBillingsTemplateById: {
    //   handler (newVal) {
    //     this.updateSelectedItems(newVal);
    //     // this.selectedHospital = newVal.officeId;
    //     this.templateId = newVal.id;
    //   },
    //   deep: true,
    // },
    // selectedOrganization: function (newValue, oldValue) {
    //   this.filteredTemplates = this.billingsTemplates.filter(template => template.organizationId === newValue);
    // },
    primaryDoctorSelected (val) {
      if (!this.staffSelected.includes(val)) {
        this.staffSelected.push(val);
      }
    },
    // async loader () {
    //   if (this.loader !== null) {
    //     const l = this.loader;
    //     this[l] = !this[l];
    //     await this.editTemplate()
    //         .then(() => {
    //           this[l] = false;
    //         })
    //         .catch(() => {
    //           this[l] = false;
    //         });
    //
    //     this.loader = null;
    //   }
    // },
  },
  async mounted () {
    this.selectedTemplate = this.getBillingsTemplatesByOffice[0]?.id;
    this.selectedItems = {};
    this.billingCodes.forEach(code => {
      this.$set(this.selectedItems, code.name, false);
    });
    await this.billinguserauthorization();
  },
  methods: {
    async billinguserauthorization () {
      await this.$store.dispatch('usersbillingsauthorization/billinguserauthorization');
      this.getBillingsTemplatesByOffice = this.billingsTemplates.filter(item => item?.officeId === this.getUserBillingsTemplateById?.officeId);
    },
    getSelectedStaff (selectedUserId) {
      const selectedStaff = this.getRelatedStaff.find(staff => staff.id === selectedUserId);
      this.selectedRoleId = selectedStaff.roleId;
    },
    editTemplate () {
      const body = {
        id: this.getUserBillingsTemplateById.id,
        organizationId: this.userDetails.organizationId,
        officeId: this.getUserBillingsTemplateById.officeId,
        userId: this.getUserBillingsTemplateById.userId,
        roleId: this.getUserBillingsTemplateById.roleId,
        rpm99453Allowed: this.selectedItems.rpm99453,
        rpm99454Allowed: this.selectedItems.rpm99454,
        rpm99457Allowed: this.selectedItems.rpm99457,
        rpm99458FirstAllowed: this.selectedItems.rpm99458First,
        rpm99458SecondAllowed: this.selectedItems.rpm99458Second,
        rtm96127Allowed: this.selectedItems.rtm96127,
        rtm98975Allowed: this.selectedItems.rtm98975,
        rtm98977Allowed: this.selectedItems.rtm98977,
        rtm98980Allowed: this.selectedItems.rtm98980,
        rtm98981Allowed: this.selectedItems.rtm98981,
        rtmG3002Allowed: this.selectedItems.rtmG3002,
      };
      this.$store.dispatch('usersbillingsauthorization/editbillinguserauthorization', body)
          .then(async (res) => {
            if (res) {
              showSuccessAlert(this.$t('success'));
              this.templateSelected = false;
              this.billingsUserAuthorization();
            } else {
              showErrorAlert(this.$t('failed'));
            }
            this.closeDrawer();
          });
    },

    updateSelectedItems (data) {
      // this.selectedHospital = data.officeId;
      this.billingCodes.forEach(code => {
        this.$set(this.selectedItems, code.name, data[`${code.name}Allowed`]);
      });
    },
    // getbillingauthorizationtemplatesbyid () {
    //   this.disable = false;
    //   this.$store.dispatch('usersbillingsauthorization/getbillingauthorizationtemplatesbyid', this.selectedTemplate).then(res => {
    //     this.templateSelected = true;
    //   });
    // },
    // getMedicalInstitutions () {
    //   this.loading = true;
    //   this.$store
    //       .dispatch('medicalInstitutions/getMedicalInstitutions')
    //       .then(() => {
    //         this.loading = false;
    //       });
    // },
    // getHospitals () {
    //   this.loading = true;
    //   this.$store.dispatch('hospitals/getHospitals').then(() => {
    //     this.loading = false;
    //   });
    // },
    closeDrawer () {
      this.billingCodes.forEach(code => {
        this.$set(this.selectedItems, code.name, false);
      });
      this.selectedHospital = null;
      this.selectedOrganization = null;
      this.selectUser = null;
      this.selectedTemplate = false;
      this.templateSelected = false;
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
    },

    billingsUserAuthorization () {
      this.$store.dispatch('usersbillingsauthorization/billinguserauthorizationV1');
    },
  },
};
</script>

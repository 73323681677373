<template>
	<v-card class="mx-auto" elevation="0">
    <v-card-title class="text-left card-header fixed-card-title2">
			<v-row>
				<v-col cols="10">
					<v-toolbar-title>{{ $t("edit-allergies") }}</v-toolbar-title>
				</v-col>
        <v-spacer></v-spacer>
        <v-icon @click="closeDrawer" class="white-icon mr-5">mdi-close</v-icon>
			</v-row>
		</v-card-title>
    <v-card-text class="px-0">
      <v-textarea :placeholder="$t('edit-allergies')" class="mt-2 px-2" :height="pageHeight(210)" solo v-model="allergies" no-resize></v-textarea>
    </v-card-text>
		<v-card-actions  class="fixed-card-actions">
			<!-- Bottom Buttons -->
			<v-row class="mb-0">
				<v-col cols="6">
					<v-btn
						@click="closeDrawer"
						elevation="0"
            class="cancel-button-style capitalize-text"
					>
						{{ $t("cancel") }}
					</v-btn>
				</v-col>
				<v-col cols="6">
					<v-btn
            :disabled="allergies === initialAllergies"
						@click="updatePatientMetaData"
            class="save-button-style"
					>
						{{ $t("save") }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card-actions>
	</v-card>
</template>

<script>

import { closeBlack, closeWhite } from '@/assets/index';
import { mapGetters, mapState } from 'vuex';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';
export default {
	data () {
		return {
			closeBlack: closeBlack,
			allergies: '',
      initialAllergies: '',
			closeWhite: closeWhite,
			isLoading: false,
		};
	},

	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			detailedUser: (state) => state.users.detailedUser,
		}),
    ...mapGetters({
      pageHeight: 'pageHeight',
    }),
	},

	async mounted () {
    this.allergies = this.getAllergies();
    this.initialAllergies = this.allergies;
	},

	methods: {
    getUserDetails () {
      this.$store.dispatch('users/getUserById', this.$route.params.id);
    },
		closeDrawer () {
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
			this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
		},

		getAllergies () {
			let parsedAllergies = this.detailedUser.userData.patientMetadata.metadataInfo;
			parsedAllergies = parsedAllergies?.replace(/<\/p>/g, '');
			parsedAllergies = parsedAllergies?.replace(/<p>/g, '\n');
			return parsedAllergies;
		},

		stringifyAllergies () {
			let allergiesString = '';
			const allergiesData = this.allergies.split('\n');

			allergiesData.forEach(allergy => {
				allergiesString += '<p>' + allergy + '</p>';
			});
			return allergiesString;
		},

		async updatePatientMetaData () {
			const body = {
				userId: this.detailedUser.userData.id,
			};
			body.metadataInfo = this.stringifyAllergies();
			try {
				await this.$store.dispatch('patientmetadata/editPatientMetaData', body).then(() => {
          showSuccessAlert(this.$t('success'));
          this.closeDrawer();
          this.getUserDetails();
        });
			} catch (error) {
        showErrorAlert(this.$t('failed-operation'));
			}
		},

	},

};
</script>

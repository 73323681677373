<template>
  <v-card
      class="mx-auto"
      elevation="0"
  >
    <v-card-title class="card-header text-left fixed-card-title2 mb-3">
      <v-row>
        <v-col cols="10">
          <v-toolbar-title >{{$t('edit')}} {{$t('verifier')}}</v-toolbar-title>
        </v-col>
        <v-col cols="2" class="text-right mt-n1">
          <v-icon color="white" @click="closeDrawer">mdi mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-container>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="verifier.rvDomain"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('domainname')"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="verifier.lowValue"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('lowvalue')"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="verifier.shortDescription"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('shortdescription')"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="verifier.longDescription"
                  :label="$t('longdescription')"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="verifier.shortDescriptionEsp"
                  :label="$t('shortdescriptionesp')"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="verifier.longDescriptionEsp"
                  :label="$t('longdescriptionesp')"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row class="mb-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="capitalize-text cancel-button-style"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              :disabled="!valid"
              @click="saveVerifier"
              class="save-button-style"
          >
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
// import { burgerMenu, closeBlack, closeWhite, rectangle, addBlack } from '@/assets/index';
import { closeWhite } from '@/assets';
import { mapGetters, mapState } from 'vuex';
// import Verifier from '../../data/models/Verifier';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
  name: 'DashboardCoreAppBar',
  components: {},
  data: () => ({
    closeWhite: closeWhite,
    valid: true,
    menu: false,
  }),
  // created () {
  //   this.$store.watch(state => state.verifiers.selectedVerifier, (newValue, oldValue) => {
  //     if (newValue == null) {
  //       this.selectedVerifier = oldValue;
  //     } else {
  //       this.selectedVerifier = newValue;
  //     }
  //   });
  // },
  computed: {
    ...mapGetters({
      // getSelectedVerifier: 'verifiers/getSelectedVerifier',
      delay: 'authentication/getDelay',
    }),
    ...mapState({
      verifier: state => state.item,
    }),
  },
  methods: {
    getAllVerifiers () {
      this.$store.dispatch('verifiers/getAllVerifiers').then(() => {
        this.closeDrawer();
      });
    },
    async saveVerifier () {
      if (this.$refs.form.validate()) {
        const body = {
          id: this.verifier.id,
          rvDomain: this.verifier.rvDomain,
          lowValue: this.verifier.lowValue,
          shortDescription: this.verifier.shortDescription,
          longDescription: this.verifier.longDescription,
          shortDescriptionEsp: this.verifier.shortDescriptionEsp,
          longDescriptionEsp: this.verifier.longDescriptionEsp,
        };

        await this.$store.dispatch('verifiers/editVerifire', body).then(res => {
          if (res.data.resFlag) {
            showSuccessAlert(this.$t('success-operation'));
            this.getAllVerifiers();
          } else {
            showErrorAlert(this.$t('failed-operation'));
          }
        });
      } else {
        this.$refs.form.validate();
      }
    },
    save (date) {
      this.$refs.menu.save(date);
    },
    closeDrawer () {
      this.$store.commit('verifiers/SELECTED_VERIFIER'); // set selectedVerifier = null

      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
      this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
    },
  },
};
</script>

<template>
  <div>
    <v-card-title class="card-header fixed-card-title2">
      {{$t('edit-medical-note-category')}}
      <v-spacer />
      <v-icon color="white" @click="closeDrawer">mdi-window-close</v-icon>
    </v-card-title>

    <v-col cols="12" lg="12" md="12" sm="12">
      <v-text-field
          outlined
          dense
          hide-details
          v-model="medicalNoteItems.medicalNoteCategoryName"
          :label="$t('medical-note-category-name')"
          @input="isSaveDisabled"
      >
      </v-text-field>
    </v-col>

    <v-col cols="12" lg="12" md="12" sm="12">
      <v-textarea
          dense
          outlined
          hide-details
          no-resize
          height="300"
          :label="$t('medical-note-category-description')"
          v-model="medicalNoteItems.medicalNoteCategoryDescription"
          @input="isSaveDisabled"
      >
      </v-textarea>
    </v-col>

    <v-col cols="12" lg="12" md="12" sm="12">
      <v-text-field
          outlined
          dense
          v-model="enteredTime"
          hide-details
          :label="$t('billings-time')"
          @input="isSaveDisabled"
      >
      </v-text-field>
    </v-col>

    <v-card-actions class="fixed-card-actions">
      <v-row dense class="pa-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              :disabled="!disableButton"
              @click="save(medicalNoteItems)"
              class="save-button-style"
          >
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { showErrorAlert } from '@/utils/utilities';

export default {
  data () {
    return {
      disableButton: false,
      medicalNoteCategoryName: '',
      medicalNoteCategoryDescription: '',
      billingsTime: null,
      updatedBillingTime: '',
    };
  },
  mounted () {
    this.getHospitals();
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.authentication.userData,
      medicalNoteItems: (state) => state.medicalNoteData.item,
    }),
    ...mapGetters({

    }),
    hospitals () {
      return this.$store.state.hospitals.hospitals;
    },
    enteredTime: {
      get () {
        if (this.medicalNoteItems && this.medicalNoteItems.billingSecondsForThisMedicalNoteCategory !== undefined) {
          const totalSeconds = this.medicalNoteItems.billingSecondsForThisMedicalNoteCategory;
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = totalSeconds % 60;
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.updatedBillingTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
          return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        } else {
          return '00:00';
        }
      },
      set (value) {
        const [minutes, seconds] = value.split(':').map(Number);
        this.updatedBillingTime = `${minutes}:${seconds}`;
      },
    },
  },
  methods: {
    isSaveDisabled (val) {
      if (val) {
        this.disableButton = true;
      }
    },
    getCategoryMedicalNotesTemplates () {
      this.$store.dispatch('medicalnotecategory/medicalnotecategorytemplate');
    },
    convertTimeToSeconds (time) {
      const [minutes, seconds] = time.split(':').map(Number);
      return (minutes * 60) + seconds;
    },
    save (item) {
      const body = {
        id: item.id,
        medicalNoteCategoryName: this.medicalNoteItems.medicalNoteCategoryName,
        medicalNoteCategoryDescription: this.medicalNoteItems?.medicalNoteCategoryDescription,
        billingSecondsForThisMedicalNoteCategory: this.convertTimeToSeconds(this.updatedBillingTime),
      };

      this.$store.dispatch('medicalnotecategory/editmedicalnotecategorytemplate', body)
          .then(res => {
            if (res.error) {
              showErrorAlert(res.error);
              this.closeDrawer();
            } else {
              this.getCategoryMedicalNotesTemplates();
              this.closeDrawer();
            }
          });
    },
    async closeDrawer () {
      this.disableButton = false;
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
    },
    async getHospitals () {
      this.loading = true;
      await this.$store.dispatch('hospitals/getHospitals').then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style>

</style>

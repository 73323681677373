<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title class="text-left card-header fixed-card-title2">{{ $t('addAdditionalNoteDetail') }}
      <v-spacer></v-spacer>
      <v-icon class="white--text float-end mr-1" @click="closeDrawer">mdi-close</v-icon>
    </v-card-title>
    <v-card-text  class="px-0" :key="rerender">
      <v-text-field
          class="mx-2 my-2"
          v-model="noteData.medicalNoteData.medicalNoteName"
          label="Medical Note"
          outlined
          readonly
          dense
          hide-details
      ></v-text-field>
      <v-textarea class="mt-2 px-2" :height="pageHeight(50)" solo no-resize v-model="medicalNote"></v-textarea>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row class="mb-0">
        <v-col cols="6">
          <v-btn
              elevation="0"
              @click="closeDrawer"
              class="cancel-button-style capitalize-text"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              :disabled="medicalNote === ''"
              @click="addAditionalNote"
              class="save-button-style"
          >
            {{ $t("save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>

import { closeBlack } from '@/assets/index';
import { mapGetters, mapState } from 'vuex';
export default {
  data () {
    return {
      template: null,
      templates: [
        { id: 1, name: 'Template 1' },
        { id: 2, name: 'Template 2' },
        { id: 3, name: 'Template 3' },
        { id: 4, name: 'Template 4' },
        { id: 5, name: 'Template 5' },
      ],
      closeBlack: closeBlack,
      medicalNote: '',
      rerender: 1,
      medicalNoteEmpty: true,
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
      userData: (state) => state.authentication.userData,
      detailedUser: (state) => state.users.detailedUser,
      noteData: (state) => state.medicalnotecategory.aditionalNoteId,
    }),
    ...mapGetters({
      hospitalId: 'authentication/getHospitalId',
      roleName: 'authentication/getRole',
      pageHeight: 'pageHeight',
    }),
  },
  watch: {
    noteData: {
      handler (newValue) {
        this.medicalNote = newValue?.medicalNoteCategoryTemplate?.medicalNoteCategoryDescription;
      },
      immediate: true,
    },
  },
  mounted () {
  },
  methods: {
    async addAditionalNote () {
      const payloadBody = {
        medicalNoteId: this.noteData.medicalNoteData.id,
        medicalStaffId: this.userData.id,
        medicalNoteContent: this.medicalNote,
        medicalNoteAdditionalData: '',
        billingSecondsForThisMedicalNoteDetail: 180,
      };
      await this.$store.dispatch('appointments/addaAdditionalMedicalNoteDetail', payloadBody)
          .then(() => {
            this.getAllNotes();
            this.closeDrawer();
          });
     },
    getAllNotes () {
      const body = {
        patientId: this.$route.params.id,
        hospitalId: localStorage.getItem('selectedHospitalAssignedId'),
      };
      this.$store.dispatch('appointments/getmedicalnoteforpatientbyid', body);
    },
    closeDrawer () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
      this.medicalNote = '';
    },
  },
};
</script>

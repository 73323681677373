<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title class="text-left card-header fixed-card-title2">{{$t('editAditionalNote')}}
      <v-spacer></v-spacer>
      <v-icon class="white--text float-end mr-1" @click="closeDrawer">mdi-close</v-icon>
    </v-card-title>
    <v-card-text  class="px-0">
      <v-textarea class="mt-2 px-2 font-size12-5" @click="disable = false" :height="pageHeight(200)" solo no-resize v-model="medicalNote"></v-textarea>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row class="mb-0">
        <v-col cols="6">
          <v-btn
              elevation="0"
              @click="closeDrawer"
              class="cancel-button-style capitalize-text"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              :disabled="disable"
              @click="updatePatientMetaData"
              class="save-button-style"
          >
            {{ $t("save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
export default {
  data () {
    return {
      medicalNote: '',
      disable: true,
    };
  },
  computed: {
    ...mapState({
      item: (state) => state.medicalNoteData.item,
    }),
    ...mapGetters({
      pageHeight: 'pageHeight',
      hospitalId: 'authentication/getHospitalId',
    }),
  },
  watch: {
    item: {
      handler (newVal) {
        if (newVal) {
          this.medicalNote = newVal.medicalNoteContent;
        }
      },
      deep: true,
    },
  },
  mounted () {
    this.medicalNote = this.item.medicalNoteContent;
    this.$store.dispatch('medicalnotecategory/medicalnotecategorytemplate');
  },
  methods: {

    async updatePatientMetaData () {
      const payloadBody = {
          id: this.item.id,
          medicalNoteContent: this.medicalNote,
          medicalNoteAdditionalData: '',
      };
      await this.$store.dispatch('appointments/editAditionalMedicalNote', payloadBody);
      this.getAllNotes();
      this.closeDrawer();
    },
    getAllNotes () {
      const body = {
        patientId: this.$route.params.id,
        hospitalId: localStorage.getItem('selectedHospitalAssignedId'),
      };
      this.$store.dispatch('appointments/getmedicalnoteforpatientbyid', body);
    },
    closeDrawer () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
      this.medicalNote = '';
      this.disable = true;
    },
  },
};
</script>
